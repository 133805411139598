@font-face {
  font-family: SFProDisplay;
  src: url(SFPRODISPLAYBLACKITALIC.OTF) format("opentype");
  font-weight: bolder;
  font-style: italic;
}

@font-face {
  font-family: SFProDisplay;
  src: url(SFPRODISPLAYBOLD.OTF) format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: SFProDisplay;
  src: url(SFPRODISPLAYHEAVYITALIC.OTF) format("opentype");
  font-weight: bolder;
  font-style: italic;
}

@font-face {
  font-family: SFProDisplay;
  src: url(SFPRODISPLAYLIGHTITALIC.OTF) format("opentype");
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: SFProDisplay;
  src: url(SFPRODISPLAYREGULAR.OTF) format("opentype");
}

@font-face {
  font-family: SFProDisplay;
  src: url(SFPRODISPLAYSEMIBOLDITALIC.OTF) format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: SFProDisplay;
  src: url(SFPRODISPLAYTHINITALIC.OTF) format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: SFProDisplay;
  src: url(SFPRODISPLAYULTRALIGHTITALIC.OTF) format("opentype");
  font-weight: 100;
  font-style: italic;
}
